.carousel-container {
  width: 80%;
  padding: 20px 20px 0 20px;
  position: inherit !important;
  align-items: baseline !important;
}
.friend-carousel-container {
  align-self: center;
  width: 60%;
}
@media only screen and (max-width:775px) {
  .friend-carousel-container {
    width: 95%;
  }
}
.carousel-container > button {
  align-self: center;
}
.carousel-dot-list {
  position: relative !important;
}

@property --progress-value {
  syntax: "<integer>";
  initial-value: 0;
  inherits: false;
}